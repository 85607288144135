<template>
  <div v-if="ruleForm.linkAddress" class="invite">
    <div class="invite-logo mobileShow" />
    <div class="mobileShow invite-title" />
    <div class="mobileShow invite-subtitle">
      <div v-for="i in guarantee" :key="i">{{ i }}</div>
    </div>
    <div class="ruleForm">
      <div class="ruleForm-title">
        填写注册信息
      </div>
      <el-form ref="ruleForm" class="ruleForm-form" :model="ruleForm" :rules="rules" label-width="0px">
        <el-form-item label="" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入姓名" clearable />
        </el-form-item>
        <el-form-item label="" prop="phone">
          <el-input v-model="ruleForm.phone" :maxlength="11" placeholder="请输入手机号" clearable />
        </el-form-item>
        <el-form-item label="" prop="code">
          <el-input
            v-model="ruleForm.code"
            :maxlength="6"
            clearable
            class="ruleForm-form-code"
            placeholder="请输入验证码"
          >
            <template #suffix>
              <div
                :class="[
                  'ruleForm-form-code-text',
                  codeDisabled && 'ruleForm-form-code-disabled'
                ]"
                @click="getVerificationCode"
              >
                {{ codeText }}
              </div>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item class="ruleForm-form-submit">
          <el-button type="primary" @click="submitForm('ruleForm')">提 交</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="mobileShow invite-course">
      <div class="course-title">五大课程</div>
      <div v-for="i in course" :key="i" class="course-item">{{ i }}</div>
    </div>
  </div>
  <div v-else class="invalid" />
</template>

<script>
import { isMobile } from '@/utils/system'
import { invitationAdd, verifyLinkAddress } from '@/api/invitation'
import { getCodeByPhone } from '@/api/login'

const Base64 = require('js-base64').Base64

const VERIFICATION_CODE = /^1[3-9]\d{9}/
export default {
  name: 'Invitation',
  data() {
    return {
      guarantee: ['保就业', '保实习', '高薪资', '四师教学'],
      course: ['Web前端+移动开发', 'javaEE+分布式开发', 'javaEE+大数据开发', '软件测试', '数据仓库ETL方向'],
      ruleForm: {
        name: '',
        phone: '',
        code: '',
        linkAddress: true
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { len: 11, pattern: VERIFICATION_CODE, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { pattern: /\d/, message: '请输入正确的验证码', trigger: 'blur' }
        ]
      },
      codeText: '获取验证码',
      codeDisabled: false
    }
  },
  created() {
    const { code } = this.$route.query
    this.verifyAddress(code)
  },
  methods: {
    async verifyAddress(code) {
      if (code) {
        try {
          const { data } = await verifyLinkAddress(code)
          this.ruleForm.linkAddress = data.linkAddress
        } catch (e) {
          this.ruleForm.linkAddress = false
        }
      } else {
        this.ruleForm.linkAddress = false
      }
    },
    getVerificationCode() {
      if (this.codeDisabled) return
      this.$refs.ruleForm.validateField('phone', info => {
        if (info) return
        this.codeDisabled = true
        let num = 59
        this.codeText = num + '秒后再次获取'
        getCodeByPhone({ phone: this.ruleForm.phone }).then(res => {
          if (res.code !== 200) {
            this.$message.error(res.msg)
          }
        })
        const interval = setInterval(() => {
          if (num === 1) {
            clearInterval(interval)
            this.codeDisabled = false
            this.codeText = '获取验证码'
            return
          }
          num--
          this.codeText = num + '秒后再次获取'
        }, 1000)
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return false
        invitationAdd(this.ruleForm).then(res => {
          if (res.code === 200) {
            this.nextStep(this.ruleForm.name, isMobile() ? '/submit' : '/index')
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    nextStep(name, path) {
      const base = Base64.encode(name)// 加密
      this.$router.replace({
        path,
        replace: true,
        query: { name: base }
      })
    }
  }
}
</script>

<style scoped lang="less">
.invalid {
  min-width: 1200px;
  overflow: auto;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  background-color: #F5F6F7;
  background-image: url("../../assets/images/pcLink.png");
  background-repeat: no-repeat;
  background-size: 789px 587px;
  background-position: center center;
}

.invite {
  text-align: left;
  min-width: 1200px;
  overflow: auto;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  background-image: url("../../assets/images/invitationBgc.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;

  .mobileShow {
    display: none;
  }

  .ruleForm {
    width: 427px;
    height: 470px;
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    position: absolute;
    top: 36vh;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;

    &-title {
      width: 100%;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #409EFF;
      background: #FFFFFF;
      padding-top: 40px;
    }

    &-form {
      padding: 68px 44px 30px;

      .el-form-item {
        height: 44px;
        margin-bottom: 20px;

        ::v-deep .el-input__inner {
          height: 44px;
        }
      }

      &-code {
        ::v-deep .el-input__inner {
          padding-right: 100px;
        }
      }

      ::v-deep .el-input__suffix-inner {
        display: flex;
        flex-direction: row-reverse;
      }

      .ruleForm-form-code-text {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #157DFB;
        padding-right: 6px;
        cursor: pointer;
      }

      .ruleForm-form-code-disabled {
        color: #999999;
      }

      &-submit {
        margin: 56px 0 0;

        .el-button {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .invalid {
    min-width: 100%;
    height: 100%;
    background-image: url("../../assets/images/mLink.png");
    background-size: 96%;
    background-position: center center;
  }

  .invite {
    min-width: 100%;
    height: 100%;
    background-image: url("../../assets/images/invitationBgcM.png");
    background-size: 100% 100%;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 47%;
      height: 30vh;
      background-image: url("../../assets/images/invitationBgcMR.png");
      background-size: auto 100%;
      background-repeat: no-repeat;
    }

    .mobileShow {
      display: block;
    }

    &-logo {
      width: 106px;
      height: 40px;
      margin: 9vw auto 0;
      background-image: url(../../assets/images/invitationLogoM.png);
      background-size: 100% 100%;
    }

    &-title {
      width: 90%;
      height: 45px;
      margin: 3.5vw auto 0;
      background-repeat: no-repeat;
      background-image: url(../../assets/images/invitationTitleM.png);
      background-size: 100% 100%;
    }

    &-subtitle {
      margin-top: 3vw;
      display: flex !important;
      justify-content: space-evenly;

      div {
        width: 20%;
        height: 34px;
        background: #FFFFFF;
        border-radius: 24px;
        font-size: 3.6vw;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #0093ff;
        line-height: 34px;
        text-align: center;
      }
    }

    .ruleForm {
      width: 80%;
      top: 30%;
      height: auto;

      &-title {
        padding-top: 5vw;
        font-size: 4vw;
      }

      &-form {
        padding: 6% 8%;

        .el-form-item {
          ::v-deep .el-input__inner {
            border-radius: 30px;
          }
        }

        ::v-deep .el-input__suffix-inner {
          align-items: center;
        }

        &-submit {
          margin: 10% 0 4% !important;

          .el-button {
            border-radius: 30px;
          }
        }
      }
    }

    &-course {
      position: absolute;
      right: 0;
      bottom: 4vw;
      width: 45vw;
      height: 32vw;
      color: #fff;

      .course-title {
        font-size: 5vw;
        font-family: Microsoft YaHei;
        font-weight: bold;
        padding-left: 4vw;
        margin-bottom: 0.8vh;
      }

      .course-item {
        font-size: 4vw;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        height: 4.4vw;
        display: flex;
        align-items: center;
        padding-left: 4vw;
        position: relative;

        &::before {
          content: '';
          width: 8px;
          height: 8px;
          background: #FFFFFF;
          position: absolute;
          top: 50%;
          left: 3px;
          transform: translateY(-50%);
        }
      }
    }
  }
}
</style>
