import request from "@/utils/request";

/*
* 获取邀请链接是否过期
* */
export const  verifyLinkAddress = address => request({
    url: `api/teacher/invitation/linkAddress?linkAddress=${address}`,
    method: 'GET',
})

/*
* 学员信息新增（邀请链接注册）
* */
export const  invitationAdd = data => request({
    url: `api/tmStudentUser/invitationAdd`,
    method: 'POST',
    data
})
